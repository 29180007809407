import React, {Component} from "react";
import { connect } from 'react-redux';
import { clearArticleLikeForSimilarRecommendationUpdatesFilter,
getArticleLikeForSimilarRecommendationUpdates,
updateFiltersForArticleLikeForSimilarRecommendationUpdatesFilter,
updateArticleLikeForSimilarRecommendations, 
getArticleLikeForSimilarUpdateSchedule,
refreshArticleLikeForSimilarRecommendations,
getArticleLikeForSimilarCountryCarrier } from "../../actions/articleLikeForAction";
import ArticleLikeForRecommendationUpdatesTable from "../../components/Article/ArticleLikeForRecommendationUpdatesTable";
import { parseObjectToString } from "../../utils/utils";
import { USER_COUNTRY_CODES } from "../../constant";
import { showConfirmationDialog, showError } from "../../actions/commonActions";

class ArticleLikeForRecommendationUpdates extends Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.props.getArticleLikeForSimilarCountryCarrier('articleRecommendation.sync.countryCarrier').then(() => {
            const entries = Object.entries(this.props.articleLikeFors.articleLikeForCountryCarrier);
            
            const userCountryCodeEntry = entries.find(([key]) => key === this.props.authentication.loggedInUser.countryCode);
            const otherEntries = entries.filter(([key]) => key !== this.props.authentication.loggedInUser.countryCode).sort((a, b) => a[0].localeCompare(b[0]));;
            
            const sortedEntries = userCountryCodeEntry ? [userCountryCodeEntry, ...otherEntries] : otherEntries;
            
            const sortedObject = Object.fromEntries(sortedEntries);
            this.props.articleLikeFors.articleLikeForCountryCarrier = sortedObject;

            let carrierCode = this.retrieveFirstCarrier(this.props.articleLikeFors.articleLikeForCountryCarrier);

            this.props.getArticleLikeForSimilarRecommendationUpdates(`orderBy=createdAt&sort=desc&size=100&page=1&filterFields[]=carrierCode&filterValues[]=${carrierCode}`).then(() => {
                this.props.getArticleLikeForSimilarUpdateSchedule('articleRecommendation.createSync.scheduler.lastRunTime')
            })  
        })
        this.props.clearArticleLikeForSimilarRecommendationUpdatesFilter();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.common.dialogType !== prevProps.common.dialogType &&
            this.props.common.dialogType === 'success'
        ) {
            window.setTimeout(() => {window.location.reload(true);}, 2000 )
        }
        if (
            this.props.common.dialogType !== prevProps.common.dialogType &&
            this.props.common.dialogType === 'refreshSuccess'
        ) {
            window.setTimeout(() => {this.props.history.push('/articles/likeForRefreshRequest');}, 2000 )
        }
    }

    retrieveFirstCarrier(countryCarrier) {
        let result = null;
        for (const country of Object.keys(countryCarrier)) {
            if (countryCarrier[country].length > 0){
                result = countryCarrier[country][0];
                break;
            }
        }
        return result;
    }

    fetchArticleLikeForSimilarRecommendationUpdates(carrierCode, pageNumber) {
        this.props.getArticleLikeForSimilarRecommendationUpdates(`orderBy=createdAt&sort=desc&size=100&page=${pageNumber}&filterFields[]=carrierCode&filterValues[]=${carrierCode}`)
    }

    renderLikeForSimilarUpdateLists(likeForSimilarRecommendationUpdatesList, showContentLoader) {
        const fields = [
            {
                name: 'description',
            },
            {
                name: 'current.optionOne',
            },
            {
                name: 'current.optionTwo',
            },
            {
                name: 'current.optionThree',

            },
            {
                name: 'recommendation.optionOne',
            },
            {
                name: 'recommendation.optionTwo',
            },
            {
                name: 'recommendation.optionThree',
            },
            
        ];

        const filterKeys = [
            {
                name: 'countryCode',
                type: 'checkBoxes',
                options: USER_COUNTRY_CODES
            }
        ];

        return(
            <ArticleLikeForRecommendationUpdatesTable fields={fields}
                       itemsResponse={likeForSimilarRecommendationUpdatesList}
                       onValueSubmit={() => this.props.getArticleLikeForSimilarRecommendationUpdates(parseObjectToString(this.props.articleLikeFors.likeForSimilarRecommendationUpdatesListFilters))}
                       showContentLoader={showContentLoader}
                       onSelectedDataSubmit={(data) => this.onSelectedDataSubmit(data)}
                       onAllDataSubmit={(data) => this.onAllDataSubmit(data)}
                       onSelectedDataRefresh={(data) => this.onSelectedDataRefresh(data)}
                       articleLikeForUpdateSchedule={this.props.articleLikeFors.articleLikeForUpdateSchedule}
                       articleLikeForCountryCarrier={this.props.articleLikeFors.articleLikeForCountryCarrier}
                       fetchData={(carrierCode, pageNumber) => this.fetchArticleLikeForSimilarRecommendationUpdates(carrierCode, pageNumber)}
            />
        )
    }
    
    onSelectedDataSubmit = (data) => {
       this.props.updateArticleLikeForSimilarRecommendations(data)
    }

    onAllDataSubmit = (data) => {
        this.props.updateArticleLikeForSimilarRecommendations(data)
    }

    onSelectedDataRefresh = (data) => {
        this.props.refreshArticleLikeForSimilarRecommendations(data)
    }

    render() {

        return(
            <>
                {this.renderLikeForSimilarUpdateLists(this.props.articleLikeFors.likeForSimilarRecommendationUpdatesList, this.props.common.showContentLoader)}
            </>
        )
    }

}

const mapStateToProps = state => ({
    articleLikeFors: state.articleLikeFors,
    authentication: state.authentication,
    common: state.common
})

export default connect(mapStateToProps, {
    getArticleLikeForSimilarRecommendationUpdates: getArticleLikeForSimilarRecommendationUpdates,
    updateFiltersForArticleLikeForSimilarRecommendationUpdatesFilter: updateFiltersForArticleLikeForSimilarRecommendationUpdatesFilter,
    clearArticleLikeForSimilarRecommendationUpdatesFilter: clearArticleLikeForSimilarRecommendationUpdatesFilter,
    updateArticleLikeForSimilarRecommendations: updateArticleLikeForSimilarRecommendations,
    getArticleLikeForSimilarUpdateSchedule: getArticleLikeForSimilarUpdateSchedule,
    refreshArticleLikeForSimilarRecommendations: refreshArticleLikeForSimilarRecommendations,
    getArticleLikeForSimilarCountryCarrier: getArticleLikeForSimilarCountryCarrier,
    showConfirmationDialog,
    showError
})(ArticleLikeForRecommendationUpdates);