export const addressFields = {
    'IT': ['street', 'number', 'zipCode', 'city', 'provinceCode'],
    'HK': ['flat', 'floor', 'building', 'number', 'street', 'district', 'city'],
    'TH': ['homeNumber', 'area', 'building', 'floor', 'lane', 'street', 'district', 'subDistrict', 'city', 'zipCode'],
    'PH': ['homeNumber', 'street', 'building', 'provinceCode', 'city', 'neighbourhood', 'zipCode']
}

export const fileUploadAcceptedFields = {
    user: [
        'email',
        'displayName',
        'phoneNumber',
        'languageCode',
        'authorities',
    ],
    articleGlobal: [
        'sku',
        'type.code',
        'manufacturer.code',
        'attributes.series.global',
        'description',
        'tagForMapping',
        'foldable'
    ],
    articleAccessory: [
        'sku',
        'countryCode',
        'carrierCode',
        'accessory.1',
        'accessory.2',
        'accessory.3',
        'accessory.4'
    ],
    severity: [
        "sku",
        "countryCode",
        "inboundDate",
        "stockLocation",
        "inboundReference",
        "imei",
        "cost.amount",
        "vendor",
        "status",
        "remarks",
        "outboundCost.amount"
    ],
    articleLikeFor: [
        'sku',
        'countryCode',
        'carrierCode'
    ],
    articleLocalizeLanguage: [
        'sku',
        'region',
        'language',
        'type.code',
        "manufacturer.code",
        "attributes.series.name.code",
        "attributes.color.name.code"
    ],
    LOCALISED_LOCAL_ARTICLE_CSV_HEADERS : {
        "LOCALIZE_ARTICLE_COUNTRY_CSV_OBJECT_TEMPLATE_LOCAL": ["sku","simType","modelNumber","upToPrice","tradeInPrice.gradeA","tradeInPrice.gradeB","tradeInPrice.gradeC","tradeInPrice.gradeD","tradeInPrice.gradeE"],
        "LOCALIZE_ARTICLE_LANGUAGE_CSV_OBJECT_TEMPLATE_LOCAL": ["sku","languageCode","typeName","manufacturerName","seriesValue","colorValue"],
        "LOCALIZE_ARTICLE_CARRIER_CSV_OBJECT_TEMPLATE_LOCAL": ["sku","carrierCode","suggestedRetailPrice"]
    },
    LOCALISED_ADMIN_ARTICLE_CSV_HEADERS : {
        "LOCALIZE_ARTICLE_COUNTRY_CSV_OBJECT_TEMPLATE_ADMIN": ["sku","countryCode","simType","modelNumber","upToPrice","tradeInPrice.gradeA","tradeInPrice.gradeB","tradeInPrice.gradeC","tradeInPrice.gradeD","tradeInPrice.gradeE"],
        "LOCALIZE_ARTICLE_LANGUAGE_CSV_OBJECT_TEMPLATE_ADMIN": ["sku","countryCode","languageCode","typeName","manufacturerName","seriesValue","colorValue"],
        "LOCALIZE_ARTICLE_CARRIER_CSV_OBJECT_TEMPLATE_ADMIN": ["sku","countryCode","carrierCode","suggestedRetailPrice"]
    }

}

export const fileUploadRequiredFields = {
    user: [
        'email',
        'displayName',
        'phoneNumber',
        'languageCode',
        'authorities'
    ],
    articleGlobal: [
        'sku',
        'type.code',
        'manufacturer.code',
        'attributes.series.global',
        'description',
        'foldable',
        'attributes.dummyDevice.value'
    ],
    articleAccessory: [
        'sku',
        'countryCode'
    ],
    severity: [
        "sku",
        "countryCode",
        "inboundDate",
        "stockLocation",
        "inboundReference",
        "imei",
        "cost.amount",
        "status"
    ],
    articleLikeFor: [
        'sku',
        'countryCode',
        'carrierCode'
    ],
    articleLocalizeLanguage: [
        'sku',
        'region',
        'language',
        'type.code',
        'manufacturer.code',
        'attributes.series.name.code',
        "attributes.color.name.code"
    ],
    articleLocalizeCountry: [
        'sku',
        'region',
        'attributes.sim.local',
        'attributes.model.local'
    ],
    articleLocalizeCarrier: [
        'sku',
        'region',
        'partner',
        'suggestedRetailPrice',
    ],
    LOCALISED_LOCAL_ARTICLE_CSV_HEADERS : {
        "LOCALIZE_ARTICLE_COUNTRY_CSV_OBJECT_TEMPLATE_LOCAL": ["sku","simType","modelNumber"],
        "LOCALIZE_ARTICLE_LANGUAGE_CSV_OBJECT_TEMPLATE_LOCAL": ["sku","languageCode","typeName","manufacturerName","seriesValue","colorValue"],
        "LOCALIZE_ARTICLE_CARRIER_CSV_OBJECT_TEMPLATE_LOCAL": ["sku","suggestedRetailPrice"]
    },
    LOCALISED_ADMIN_ARTICLE_CSV_HEADERS : {
        "LOCALIZE_ARTICLE_COUNTRY_CSV_OBJECT_TEMPLATE_ADMIN": ["sku","countryCode","simType","modelNumber"],
        "LOCALIZE_ARTICLE_LANGUAGE_CSV_OBJECT_TEMPLATE_ADMIN": ["sku","countryCode","languageCode","typeName","manufacturerName","seriesValue","colorValue"],
        "LOCALIZE_ARTICLE_CARRIER_CSV_OBJECT_TEMPLATE_ADMIN": ["sku","countryCode","suggestedRetailPrice"]
    }

}

export const fileUploadRequiredHeaders = {
    articleLikeForSimilar: [
        'sku',
        'countryCode',
        'carrierCode',
        'likeForSimilar'
    ],
    articleLikeForUpgrade: [
        'sku',
        'countryCode',
        'carrierCode',
        'likeForUpgrade'
    ]

}

export const formRequiredFields = {
    "localizeUpload": ['launchDate']
}